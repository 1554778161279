import { useIsVisible } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Blocks from '../../components/blocks/Blocks';

import styles from './polaroid.module.css';

const propTypes = {
    className: PropTypes.string,
    layout: PropTypes.string,
};

const defaultProps = {
    className: null,
    layout: null,
};

function TourismeLaurentidesPolaroid({ className, layout, blocks }) {
    const { ref, visible = false } = useIsVisible({ rootMargin: '-40%', persist: true });
    const blocksWithSize = useMemo(
        () =>
            blocks.map((it) => ({
                size: 'medium',
                ...it,
            })),
        [blocks],
    );
    return (
        <div
            className={classNames(
                styles.container,
                {
                    [styles.hidden]: !visible,
                },
                className,
            )}
            data-layout={layout}
            ref={ref}
        >
            <div className={styles.inner}>
                <Blocks blocks={blocksWithSize} blockClassName={styles.polaroid} />
            </div>
        </div>
    );
}

TourismeLaurentidesPolaroid.propTypes = propTypes;
TourismeLaurentidesPolaroid.defaultProps = defaultProps;

export default TourismeLaurentidesPolaroid;
